
import { Component, Vue } from 'vue-property-decorator'
import { VideoInfo } from '../../types/common'
import MonitorControl from '@/components/monitorControl/Index.vue'
@Component({
  components: { MonitorControl }
})
export default class VehicleList extends Vue {
  private currentPlayId = ''

  private info = {
    deviceStatus: ''
  }

  private tableData = {
    loading: false,
    tr: [
      {
        label: '设备名称',
        prop: 'deviceTypeName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '设备串号',
        prop: 'deviceNumber',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '设备状态',
        prop: 'deviceStatus',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  page = 1
  size = 10
  total = 0

  get projectId () {
    return this.$route.params.projectId || ''
  }

  created (): void {
    if (this.projectId) {
      this.getData()
    } else {
      this.$router.push({ name: 'vehicleList' })
    }
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.vehicle.selectVehicleDetailPage, {
      ...this.info,
      deviceTypeId: '1002',
      projectId: this.projectId,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  // 点击播放将通道号push进播放列表
  onPlay (row: VideoInfo) {
    this.currentPlayId = row.deviceId
    ;(this.$refs.monitorControl as any).onPlay(row)
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
