
import { Component, Vue } from 'vue-property-decorator'
import { VideoInfo } from '../../types/common'
declare let WebControl: any
declare let EZUIKit: any
@Component
export default class MonitorControl extends Vue {
  private videoCurrentInfo: VideoInfo | {} = {}
  private switchVideo = 0 // 0实时预览 1录像回放
  private dialogVisible = false
  private oWebControl: any = null
  private ezuikitVideo: any = null
  private token = ''
  private appKey = ''
  private ezuikiToken = ''
  private swfWidth = 0
  private swfHeight = 0

  created () {
    this.getToken()
    this.getEzuikitToekn()
    window.addEventListener('resize', this.onResize)
  }

  // 监听页面离开，销毁视频控件实例
  destroyed () {
    this.ezuikitVideo && this.ezuikitVideo.stop()
    if (this.oWebControl) {
      this.oWebControl.JS_HideWnd() // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
      this.oWebControl.JS_Disconnect()
    }
    // 删除页面监听事件
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('scroll', this.onScroll)
  }

  /** 海康云眸视频操控 */
  // 云眸视频获取token、key
  getToken () {
    this.$axios.get(this.$apis.monitor.getToken).then(res => {
      this.token = res.token
      this.appKey = res.appKey
    })
  }

  // 萤石获取token
  getEzuikitToekn () {
    this.$axios.get(this.$apis.monitor.getEzuikitToken).then(res => {
      this.ezuikiToken = res.accessToken
    })
  }

  // object判断播放插件类型 2萤石 1海康
  onPlay (row: VideoInfo) {
    const info = this.videoCurrentInfo as VideoInfo
    this.videoCurrentInfo = row
    if (row.objectId === '2') {
      if (info.objectId === '2') {
        // 直接切换链接，云台控制失效，暂定每次都直接清除，重新渲染
        // this.ezuikitChange()
        this.ezuikitClear()
      } else {
        this.haikangClear()
      }
      this.ezuikitInit()
    } else {
      if (info.objectId === '1') {
        this.haikangAddVideo()
      } else {
        this.ezuikitClear()
        this.WebControlInit()
      }
    }
  }

  // 云眸初始化基座
  WebControlInit () {
    this.$nextTick(() => {
      console.log('==========================')
      console.log('WebControlInit')
      this.swfWidth = (this.$refs.videoPlay as any).offsetWidth
      this.swfHeight = (this.$refs.videoPlay as any).offsetWidth / 4 * 3
      this.oWebControl = new WebControl({
        szPluginContainer: 'playWnd',
        iServicePortStart: 14510, // 对应 LocalServiceConfig.xml 中的ServicePortStart值
        iServicePortEnd: 14519, // 对应 LocalServiceConfig.xml 中的ServicePortEnd值
        cbConnectSuccess: () => {
          this.oWebControl.JS_StartService('window', {
            // WebControl实例创建成功后需要启动服务
            dllPath: './chain/cloudTransform.dll' // 值"./VideoPluginConnect.dll"写死
          }).then(() => {
            // 启动插件服务成功
            this.oWebControl.JS_SetWindowControlCallback({
              // 设置消息回调
              cbIntegrationCallBack: (oData: {responseMsg: { eventName: string }}) => {
                console.log(oData.responseMsg, '成功')
                if (oData.responseMsg.eventName === 'FireOcxVersion') {
                  this.haikangAddVideo()
                }
              }
            })
            this.oWebControl.JS_CreateWnd('playWnd', this.swfWidth, this.swfHeight)
          }, (err: string) => {
            console.log('启动插件失败', err)
            // 启动插件服务失败
          })
        },
        cbConnectError: () => {
          // 插件启动失败
          this.oWebControl = null
          this.dialogVisible = true
          console.log('确认本地进程是否已安装并开启成功！')
        }
      })
    })
  }

  // 萤石初始化
  ezuikitInit () {
    this.swfWidth = (this.$refs.videoPlay as any).offsetWidth
    this.swfHeight = (this.$refs.videoPlay as any).offsetWidth / 4 * 3
    const info = this.videoCurrentInfo as VideoInfo
    const url = this.switchVideo === 0 ? info.ezopenLive : info.ezopenRec
    this.$nextTick(() => {
      this.ezuikitVideo = new EZUIKit.EZUIKitPlayer({
        id: 'ezuikit',
        accessToken: this.ezuikiToken,
        url: url,
        template: 'theme',
        autoplay: true,
        handleSuccess: (data: any) => { console.log('播放成功回调', data) },
        handleError: (data: any) => console.log('播放失败回调1', data),
        decoderVersion: 'v3.4',
        width: this.swfWidth,
        height: this.swfHeight
      })
    })
  }

  // 暂时去掉，目前切换云台控制会有问题，后续看萤石官网修复情况 // 萤石视频播放
  // ezuikitChange () {
  //   const info = this.videoCurrentInfo as VideoInfo
  //   const url = this.switchVideo === 0 ? info.ezopenLive : info.ezopenRec
  //   this.ezuikitVideo.stop()
  //   this.ezuikitVideo.play({ url: url })
  // }

  // 萤石清除
  ezuikitClear () {
    if (this.ezuikitVideo) {
      this.ezuikitVideo.stop()
      ;(document.getElementById('ezuikit') as Element).innerHTML = ''
      this.ezuikitVideo = null
    }
  }

  // 海康清除
  haikangClear () {
    if (this.oWebControl) {
      ;(document.getElementById('playWnd') as Element).innerHTML = ''
      this.oWebControl.JS_HideWnd()
      this.oWebControl = ''
    }
  }

  // 海康视频初始化视频
  haikangAddVideo () {
    if (this.oWebControl) {
      console.log('==========================')
      console.log('haikangAddVideo')
      const initJson: any = {
        funcName: 'Init',
        arguments: {
          type: 'chain',
          initModel: 0,
          response: {}
        }
      }
      initJson.arguments.response.data = {
        appKey: this.appKey,
        ezvizToken: this.token,
        videoLevel: 1,
        uploadUrl: 'uploadUrl',
        accessKeyId: 'accessKeyId',
        accessKeySecret: 'accessKeySecret',
        accessToken: 'accessToken',
        bucket: 'bucket',
        object: 'object'
      }
      initJson.arguments = encodeURI(
        JSON.stringify(initJson.arguments)
      )
      this.oWebControl.JS_RequestInterface(initJson).then(() => {
        this.haikangPlay()
      })
    }
  }

  // 海康播放视频
  haikangPlay () {
    const info = this.videoCurrentInfo as VideoInfo
    const data = {
      deviceSerial: info.deviceNumber,
      channelId: info.channelId,
      channelName: '',
      channelNo: 1,
      channelStatus: 1,
      channelType: '10300'
    }
    console.log('============================================playVideo==============================================')
    console.log(data)
    this.oWebControl.JS_RequestInterface({
      funcName: this.switchVideo === 0 ? 'StartPreview' : 'StartPlayback',
      arguments: encodeURI(
        JSON.stringify({
          response: {
            code: 0,
            message: null,
            data: data,
            gpuMode: 1 // 是否启用GPU硬解，0-不启用，1-启用
          }
        })
      )
    })
  }

  // 页面大小改变
  onResize () {
    if (this.oWebControl) {
      this.swfWidth = (this.$refs.videoPlay as any).offsetWidth
      this.swfHeight = (this.$refs.videoPlay as any).offsetWidth / 4 * 3
      this.oWebControl.JS_Resize(this.swfWidth, this.swfHeight)
    } else if (this.ezuikitVideo) {
      this.ezuikitClear()
      this.ezuikitInit()
    }
  }

  // 页面滚动
  onScroll (e: any) {
    if (this.oWebControl) {
      if (e.srcElement.scrollTop <= 0) {
        this.oWebControl.JS_RepairPartWindow(0, 0, this.swfWidth, this.swfHeight)
      } else {
        this.oWebControl.JS_CuttingPartWindow(0, 1, this.swfWidth, this.swfHeight)
      }
    }
  }

  // 预览与回放切换
  switchTo (val: number) {
    this.switchVideo = val
    const info = this.videoCurrentInfo as VideoInfo
    if (info.objectId === '2') {
      this.ezuikitClear()
      this.ezuikitInit()
    } else if (info.objectId === '1') {
      if (this.oWebControl) {
        this.oWebControl.JS_RequestInterface({
          funcName: 'ChangeModel',
          arguments: encodeURI(
            JSON.stringify({
              model: val,
              gpuMode: 1
            })
          )
        })
        this.haikangAddVideo()
      }
    }
  }
}
